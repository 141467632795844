/* @import '~antd/dist/antd.css'; */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  min-height: 100vh
}

#root {
  height: 100%;
}

.error {
  color: #ff4d4f;
}
.ant-table table { font-size: 12px; }


.not-verified {
  background: #faf81438
}

.feedback {
  background: #faf81438
}
.completed {
  background: #14fa2338
}

.ant-picker-calendar-date-content {
  height: auto !important;
  min-height: 86px !important;
}
.events {
  padding-left: 0;
  height: 200px;
  font-size: 12px;
}

.ant-card-head-title {
  white-space: pre-line !important;
  max-width: 340px;
}

/* .ant-layout-sider {
  width: 240px !important;
  min-width: 240px !important;
} */

.ant-menu-sub {
  width: 240px !important;
  min-width: 240px !important;
}